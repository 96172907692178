import {
  styled,
  keyframes,
} from 'config/stitches.config'

import Text from '@/UI/Text'

const moveInitial = 'calc(-30% + 20vw)'
const moveFinal = 'calc(-50% + 20vw)'

const marquee = keyframes({
  '0%': { transform: `translate3d(${moveInitial}, 0, 0)` },
  '100%': { transform: `translate3d(${moveFinal}, 0, 0)` },
})


export const StyledMarquee = styled('div', {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  variants: {
    absolute: {
      true: {
        position: 'absolute',
        left: 0,
        top: '0%',
        '@md': {
          top: '50%',
          transform: 'translateY(-50%)',
        }
      }
    }
  }
})
export const StyledMarqueeInner = styled('div', {
  cursor: 'pointer',
  width: 'fit-content',
  display: 'inline-flex',
  position: 'relative',
  transform: `translate3d(${moveInitial}, 0, 0)`,
  animation: `${marquee} 16s linear infinite`,
  animationPlayState: 'running',

  [`${Text}`]: {
    fontWeight: '$1',
    margin: '0 $3',
  },

  variants: {
    theme: {
      default: { color: '$lightGray' },
      inverted: {
        color: '$darkGray',
        '@md': { color: '#0E0E0E' }
      }
    }
  },
  defaultVariants: { theme: 'default' }
})
