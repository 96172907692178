import {
  styled,
  rem
} from 'config/stitches.config'


export const StyledContactRow = styled('div', {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  paddingY: '$3',
  borderTop: '1px solid $white',
  '@md': { flexWrap: 'nowrap', }
})

export const StyledContactTitle = styled('div', {
  flexBasis: '100%',
  flexShrink: 0,
  marginBottom: '$2',
  '@md': { flexBasis: '60%' }
})

export const StyledContactContent = styled('div', {
  flexBasis: '100%',
  '@md': { flexBasis: '40%' },
  'h4': {
    color: '$white',
    marginBottom: rem(80),
  },
  'p': {
    marginTop: 0,

    color: '$accessibleLightGray',
    a: { color: 'inherit' }

  }
})
