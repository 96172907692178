import React, {
  createContext,
  useEffect,
  useState
} from 'react'

export const MouseContext = createContext({
  cursorType: '',
  cursorText: '',
  cursorChangeType: () => {},
  cursorChangeText: () => {},
})

const MouseProvider = (props) => {
  const [cursorType, setCursorType] = useState('default')
  const [cursorText, setCursorText] = useState('')
  const [cursorBlendMode, setCursorBlendMode] = useState('difference')
  const [mouseVisible, setMouseVisible] = useState(true)

  const cursorChangeType = (type = 'default') => {
    setCursorType(type)
  }

  const cursorChangeText = (text = '') => {
    setCursorText(text)
  }
  const cursorChangeBlendMode = (blendMode = 'difference') => {
    setCursorBlendMode(blendMode)
  }
  const onMouseLeave = () => {
    setMouseVisible(false)
  }

  const onMouseEnter = () => {
    setMouseVisible(true)
  }

  useEffect(()=> {
    document.addEventListener('mouseleave', onMouseLeave)
    document.addEventListener('mouseenter', onMouseEnter)

    return function cleanup() {
      document.removeEventListener('mouseleave', onMouseLeave)
      document.removeEventListener('mouseenter', onMouseEnter)
    }
  })


  return (
    <MouseContext.Provider
      value={{
        cursorText,
        cursorType,
        cursorBlendMode,
        cursorChangeType,
        cursorChangeText,
        cursorChangeBlendMode,
        mouseVisible,
      }}
    >
      {props.children}
    </MouseContext.Provider>
  )
}

export default MouseProvider
