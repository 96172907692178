import {
  styled, rem
} from 'config/stitches.config'


export const StyledDropTrigger = styled('button', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 0,
  backgroundColor: 'transparent',
  border: 'none',
  padding: 0,
  position: 'relative',
  zIndex: 1,

  '.icon': {
    width: 'auto',
    paddingLeft: '$1',

    'svg': { fill: 'none' }
  }
})

export const StyledDropList = styled('ul', {
  padding: 0,
  margin: 0,
  listStyle: 'none',

})

export const StyledDropListContainer = styled('div', {
  zIndex: 0,
  content: '',
  position: 'absolute',
  top: 0,
  left: 0,
  size: '100%',
  br: 30,
  display: 'table',
  variants: {
    isOpen: {
      true: {
        paddingTop: 60,
        paddingBottom: 30,

        height: 'auto',
        '@md': { paddingY: 30, }
      }
    },
    overlap: { false: {} },
  },
  compoundVariants: [
    {
      overlap: false,
      isOpen: true,
      css: {
        position: 'relative',
        marginTop: -48,
        '@md': {
          position: 'absolute', marginTop: 0,
        }
      }
    }
  ]
})

export const StyledDropDown = styled('div', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  zIndex: 2,
  variants: {
    isOpen: {
      true: {
        zIndex: 10,
        '.icon svg': {
          transform: 'rotate(180deg)', transformOrigin: 'center center'
        }
      }
    },
    theme: {
      default: {
        background: 'transparent',
        border: 'none',
        padding: 0,

        [StyledDropList]: {
          boxShadow: '0px 0px 8px rgba(51, 51, 51, 0.1)',
          marginTop: '$1',
          background: '$white',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '$1',
          padding: '$1'
        }
      },
      boxed: {
        flexDirection: 'column',
        [StyledDropTrigger]: {
          paddingX: rem(24),
          paddingY: rem(16),
        },

        [StyledDropListContainer]: {
          border: '1px solid $black',
          backgroundColor: '$white',
        },

        [StyledDropList]: {
          marginTop: '$2',
          paddingX: '$3'
        }
      }
    }
  },
  defaultVariants: { theme: 'default' }
})
