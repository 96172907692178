import React from 'react'

import { StyledTag } from './styles'

const Tag = ({
  label,
  theme
}) => (
  <StyledTag theme={theme}>
    {label}
  </StyledTag>
)

export default Tag
