import React from 'react'

import Column from '@/UI/Column'
import Text from '@/UI/Text'

import {
  StyledReviewSlide,
  StyledReviewSlideHeader
} from './styles'

const ReviewSlide = ({
  title,
  subtitle,
  content,
}) => {
  return (
    <StyledReviewSlide>
      <Column col={{ '@md': 4 }}>
        <StyledReviewSlideHeader>
          <Text
            as='h4'
            size={{
              '@initial': 'p1-mid-mobile',
              '@md': 'p1-mid'
            }}
          >{
              title}
          </Text>
          <Text
            size={{
              '@initial': 'p3-mid-mobile',
              '@md': 'p3-mid'
            }}
          >
            {subtitle}
          </Text>
        </StyledReviewSlideHeader>
      </Column>
      <Column col={{ '@md': 8 }}>
        <Text
          as='p'
          size={{
            '@initial': 'p1-mobile',
            '@md': 'p1'
          }}
        >{content}</Text>
      </Column>
    </StyledReviewSlide>
  )
}


export default ReviewSlide
