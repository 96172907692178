import React from 'react'
import { StyledReviewsCounter } from './styles'

const Counter = ({
  currentSlide,
  totalSlides
}) => {
  const CounterLabel = `${currentSlide}<span> su </span>${totalSlides}`

  return (
    <StyledReviewsCounter
      className="ReviewsCounter"
      size={'p3-mid'}
      dangerouslySetInnerHTML={{ __html: CounterLabel }} />
  )
}

export default Counter
