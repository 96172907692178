import {
  styled,
  rem
} from 'config/stitches.config'

export const StyledSliderNavigation = styled('nav', {
  display: 'flex',
  alignItems: 'center',
  columnGap: rem(64)
})
