import React from 'react'

import SectionContainer from '@/Section/Container'
import ParagraphWithAnimation from '@/ParagraphWithAnimation'

const OnScrollParagraph = ({
  sectionGeneral: { theme },
  sectionContent
}) => {
  return (
    <SectionContainer
      theme={theme}>

      <ParagraphWithAnimation
        theme={theme}
        {...sectionContent}
      />
    </SectionContainer>
  )
}

export default OnScrollParagraph
