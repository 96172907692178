import React from 'react'

import ServicesPreview from '@/Services/Preview'
import ServicesRows from '@/Services/Rows'
import ServicesImages from '@/Services/Images'

const Services = ({
  data,
  layout,
  theme
}) => {
  if (layout === 'rows') return <ServicesRows data={data}/>
  if (layout === 'images') return <ServicesImages theme={theme} data={data} />

  return <ServicesPreview data={data} />
}

export default Services
