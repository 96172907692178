import React from 'react'

import SectionContainer from '@/Section/Container'
import ParagraphsWithTitle from '@/ParagraphsWithTitle'

const Paragraphs = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) => {
  return (
    <SectionContainer
      theme={theme}
      sectionHeader={sectionHeader}
      cursor={{ type: theme }}>

      <ParagraphsWithTitle items={sectionContent.paragraphsWithTitle}/>
    </SectionContainer>
  )
}

export default Paragraphs
