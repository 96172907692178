import React, {
  createContext, useState
} from 'react'


export const MenuContext = createContext({
  menus: {},
  open: false,
  menuChangeHandler: () => {},
  handleAnimating: () => {},
})

const MenuProvider = ({
  menus,
  language,
  slug,
  children,
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)

  const menuChangeHandler = () => {
    document.body.style.overflow = !menuOpen ? 'hidden' : 'visible'
    setMenuOpen(!menuOpen)
  }

  const handleAnimating = (value) => {
    setIsAnimating(value)
  }

  return (
    <MenuContext.Provider
      value={{
        language,
        slug,
        menus,
        menuOpen,
        menuChangeHandler,
        handleAnimating,
        isAnimating
      }}
    >
      {children}
    </MenuContext.Provider>
  )
}

export default MenuProvider
