import {
  styled,
  rem,
  col,
} from 'config/stitches.config'

const Container = styled('div', {
  boxSizing: 'border-box',
  variants: {
    layout: {
      default: {
        paddingX: '$2',
        '@md': { paddingX: rem(72), }
      },
      full: { paddingX: 0 }
    },
    size: {
      1: { width: col(1) },
      2: { width: col(2) },
      3: { width: col(3) },
      4: { width: col(4) },
      5: { width: col(5) },
      6: { width: col(6) },
      7: { width: col(7) },
      8: { width: col(8) },
      9: { width: col(9) },
      10: { width: col(10) },
      11: { width: col(11) },
      12: { width: col(12) },
    }

  },
  defaultVariants: {
    layout: 'default',
    size: 12
  }
})

export default Container
