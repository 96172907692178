import React from 'react'
import ImagesMosaic from '@/ImagesMosaic'

import { StyledServicesImage } from './styles'

const ServicesImage = ({
  data,
  theme
}) => {
  return (
    <StyledServicesImage>
      <ImagesMosaic items={data} theme={theme} />
    </StyledServicesImage>
  )
}

export default ServicesImage
