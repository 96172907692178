import React from 'react'

import SectionContainer from '@/Section/Container'
import Partners from '@/Partners'

const PartnersList = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) =>  (
  <SectionContainer
    theme={theme}
    sectionHeader={sectionHeader}
  >

    <Partners {...sectionContent.partners} theme={theme} />
  </SectionContainer>
)

export default PartnersList
