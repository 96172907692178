import React from 'react'

import { StyledFieldError } from './styles'

const FieldError = ({
  touched,
  error
}) => (
  touched && error
    ? (
      <StyledFieldError className="error">
        <span>{error}</span>
      </StyledFieldError>
    )
    : null
)

export default FieldError
