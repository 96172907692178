import {
  styled,
  rem
} from 'config/stitches.config'

import Text from '@/UI/Text'

import { StyledSliderNavigation } from '@/Sliders/Navigation/styles'

export const StyledReviewsCounter = styled(Text, {
  zIndex: 0,
  position: 'absolute',
  textAlign: 'center',
  width: '100%',
  fontWeight: '$1',

  '@md': {
    position: 'static',
    width: 'auto',
    flexShrink: 0
  },

  'span': {
    color: '$gray',
    fontWeight: '$2',
  }
})

export const StyledReviewsListInfos = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'relative',
  marginTop: rem(64),

  [`${StyledSliderNavigation}`]: {
    position: 'relative',
    zIndex: 1,
    width: '100%',
    justifyContent: 'space-between',

    '@md': { justifyContent: 'flex-end', }
  }
})
