import React from 'react'

import Marquee from 'sections/Marquee'

import SectionContainer from '@/Section/Container'
import Services from '@/Services'

const ServicesList = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) => (
  <SectionContainer
    theme={theme}
    sectionHeader={sectionHeader}
    footer={
      sectionContent.marquee ?
        <Marquee
          {...sectionContent.marquee}
          theme={theme}
          css={{
            paddingX: 0,
            paddingTop: 0,
            paddingBottom: 80,
            '@md': { paddingBottom: 100 }
          }}
        />
        : null
    }
  >
    <Services
      theme={theme}
      data={sectionContent.services}
      layout={sectionContent.layout}/>
  </SectionContainer>
)

export default ServicesList
