import React, { useContext } from 'react'

import { StyledDropDownButton } from './styles'
import { DropDownCtx } from 'contexts/DropDown'

const DropDownButton = ({
  id,
  cluster,
  title,
  onClick,
  selected = false,
  autoClose = false,
}) => {
  const {
    isOpen,
    setOpen
  } = useContext(DropDownCtx)

  return (
    <StyledDropDownButton
      selected={selected}
      theme="reset"
      onClick={() => {
        onClick(cluster, id, title)
        if (autoClose) setOpen(!isOpen)
      }}
    >
      {title}
    </StyledDropDownButton>
  )
}
export default DropDownButton
