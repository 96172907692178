import React from 'react'

import Icon from '@/UI/Icon'

import { StyledOtherPartner } from './styles'

const OtherPartner = ({
  theme,
  ctaUrl,
  title
}) => {
  return (
    <StyledOtherPartner className="PartnerLink" theme={theme} href={ctaUrl} >
      {title}
      <Icon name="arrowRight" />
    </StyledOtherPartner>
  )
}

export default OtherPartner
