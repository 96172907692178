import React from 'react'
import { motion } from 'framer-motion'

import Heading from '@/UI/Heading'
import Grid from '@/UI/Grid'

import FeaturedPartner from './Featured'
import OtherPartner from './Other'
import { FadeIn } from 'animations'

import {
  StyledPartners,
  StyledFeaturedPartnersList,
  StyledOtherPartners,
  StyledOtherPartnersList,
} from './styles'

const container = {
  hidden: {  },
  show: {
    transition: {
      duration: 0.6,
      staggerChildren: 0.3
    }
  },
}

const Partners = ({
  title,
  featured,
  others,
  theme
}) => (
  <StyledPartners>
    {
      featured &&
      <StyledFeaturedPartnersList
        as={motion.div}
        variants={container}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
      >
        <Grid columns={{ '@md': 2 }}>
          {
            featured.map(partner => <FeaturedPartner key={partner.id} {...partner} theme={theme} />)
          }
        </Grid>
      </StyledFeaturedPartnersList>
    }

    {
      others &&
      !!others.length &&
      <StyledOtherPartners
        as={motion.div}
        variants={FadeIn()}
        initial="hidden"
        whileInView="show"
      >
        <Heading as="h3" size="s2">{title}</Heading>
        <StyledOtherPartnersList>
          {
            others.map(partner => <OtherPartner r key={partner.id} {...partner} theme={theme} />)
          }
        </StyledOtherPartnersList>
      </StyledOtherPartners>
    }

  </StyledPartners>
)

export default Partners
