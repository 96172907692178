import {
  styled, rem, keyframes

} from 'config/stitches.config'

import { StyledSectionContent } from '@/Section/Container/styles'


const scaleUp = keyframes({
  '0%': { transform: 'scale(1) translateZ(0)' },
  '100%': { transform: 'scale(1.1) translateZ(0)' },
})

export const StyledImageWithTag = styled('div', {
  '&.inView': {
    'img': {
      animation: `${scaleUp} 1.8s`,
      animationDelay: '.6s',
      animationFillMode: 'forwards'
    },
  },

  '&:last-child': {
    display: 'none',
    '@md': { display: 'inline-block', }
  },
  '&:first-child': { display: 'inline-block' },
  '@md': {
    maxWidth: 440,
    width: '100%'
  },

  variants: {
    count: {},
    alignment: {
      Center: {
        maxWidth: 495,
        width: '100%',
        '&:last-child': { marginTop: rem(133) },
      },
      Right: {
        '@md': {
          maxWidth: 300,
          width: '100%'
        },
        '&:last-child': { marginTop: rem(133) },
        '&:first-child': { marginTop: 0 },
      }
    }
  },
  compoundVariants: [
    {
      alignment: 'Right',
      count: 1,
      css: {
        '@md': {
          maxWidth: 820,
          width: 'auto'
        },
      }
    }
  ]
})

export const StyledImagesWithTag = styled(StyledSectionContent, {
  paddingY: 0,
  display: 'inline-flex',
  alignment: 'flex-start',
  justifyContent: 'flex-start',
  columnGap: rem(65),

  variants: {
    alignment: {
      Center: {
        '@md': { paddingX: 190, },
        justifyContent: 'center',

        'img': { width: '100%' }
      },
      Right: { justifyContent: 'flex-end', }
    }
  }
})


