import {
  styled,
  rem
} from 'config/stitches.config'

export const StyledReviewSlide = styled('div', {
  display: 'flex',
  flexDirection: 'column',

  '@md': {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
})

export const StyledReviewSlideHeader = styled('div', {
  borderBottom: '1px solid $lightGray',
  paddingRight: '$3',
  paddingBottom: '$2',
  marginBottom: '$3',

  'h4': {
    fontWeight: '$1',
    marginBottom: rem(12)
  },

  'span': { color: '$darkGray', },

  '@md': {
    borderBottom: 'none',
    paddingBottom: '0',
    marginBottom: '0',
  }

})
