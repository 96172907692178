
export const SlideUp = ({ duration = 0.4 } = {}) => ({
  hidden: {
    y: '100%',
    opacity: 0
  },
  show: {
    opacity: 1,
    y: '0%',
    transition: { duration }
  },
})

export const ScaleX = ({
  duration = 0.4,
  opts = {}
} = {}) => ({
  hidden: { scaleX: 0 },
  show: {
    scaleX: 1,
    transition: {
      duration,
      ...opts
    }
  },
})

export const FadeIn = (opts = {}) => ({
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: { ...opts }
  },
}
)
