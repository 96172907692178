import React from 'react'

import withCustomCursor from 'hoc/withCustomCursor'

import Heading from '@/UI/Heading'
import Text from '@/UI/Text'
import Icon from '@/UI/Icon'
import Button from '@/UI/Button'

import {
  StyledServicesRow,
  StyledServicesRowHeader,
  StyledServicesRowIcon,
  StyledServicesRowContent
} from './styles'


const ServiceRow = ({
  link,
  icon,
  title,
  excerpt,
  cursor,
  onMouseEnter,
  onMouseLeave,
}) => (

  <StyledServicesRow
    {...{
      cursor,
      onMouseEnter,
      onMouseLeave
    }}
    {...link} >
    <StyledServicesRowHeader>
      <StyledServicesRowIcon>
        <Icon name={icon} />
      </StyledServicesRowIcon>
      <Heading as="h3" size="s1">
        {title}
      </Heading>

      <Button
        as={'div'}
        size="verySmall"
        theme={'default'}
      >
        <Icon css={{ size: '80%' }} theme="inverted" name="arrowRight" />
      </Button>
    </StyledServicesRowHeader>
    <StyledServicesRowContent>
      <Text as="p" size={{
        '@initial': 'p1-mobile',
        '@md': 'p1'
      }}>{excerpt}</Text>
    </StyledServicesRowContent>
  </StyledServicesRow>
)

export default withCustomCursor(ServiceRow)
