import React from 'react'

import Icon from '@/UI/Icon'
import Button from '@/UI/Button'

import {
  StyledSeparatedIconCta,
  StyledSeparatedIconCtaSlot
} from './styles'

const SeparatedIconCta = ({
  label = null,
  theme,
  ...rest
}) => {
  return (
    <StyledSeparatedIconCta
      {...rest}
    >
      <Button theme={theme} className='SeparatedIconCta-label no-hover' as="span">{label}</Button>
      <StyledSeparatedIconCtaSlot theme={theme} className="SeparatedIconCta-icon">
        <Icon name="arrowRight" />
      </StyledSeparatedIconCtaSlot>
    </StyledSeparatedIconCta>
  )
}

export default SeparatedIconCta
