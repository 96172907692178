import React from 'react'

import SectionContainer from '@/Section/Container'
import FormComponent from '@/Form'

const Form = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) => (
  <SectionContainer
    theme={theme}
    sectionHeader={sectionHeader}
  >
    <FormComponent {...sectionContent} />
  </SectionContainer>
)

export default Form
