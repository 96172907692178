import {
  styled,
  rem,
} from 'config/stitches.config'

import Line from '@/UI/Line'

const Divider = styled(Line, {
  maxWidth: 900,
  marginTop: '$4',
  marginBottom: '$3',
  transformOrigin: 'center center',

  '@md': {
    marginTop: rem(64),
    marginBottom: rem(48),
  }
})


export default Divider
