import {
  styled, rem
} from 'config/stitches.config'

export const StyledParagraphsWithTitle = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  rowGap: rem(40),
  '@middle': { columnGap: '$4' },
  '@lg': { columnGap: rem(120), }
})
