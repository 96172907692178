const utils = {
  marginX: (value) => ({
    marginLeft: value,
    marginRight: value,
  }),
  marginY: (value) => ({
    marginTop: value,
    marginBottom: value,
  }),
  paddingX: (value) => ({
    paddingLeft: value,
    paddingRight: value,
  }),
  paddingY: (value) => ({
    paddingTop: value,
    paddingBottom: value,
  }),
  size: (value) => ({
    width: value,
    height: value,
  }),
  // A property to apply linear gradient
  linearGradient: (value) => ({ backgroundImage: `linear-gradient(${value})`, }),

  // An abbreviated property for border-radius
  br: (value) => ({ borderRadius: value, })
}


export default utils
