import React from 'react'

import Marquee from 'sections/Marquee'

import SectionContainer from '@/Section/Container'
import ImagesMosaic from '@/ImagesMosaic'


const Scenarios = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) => (
  <SectionContainer
    theme={theme}
    sectionHeader={sectionHeader}
    footer={
      sectionContent.marquee ?
        <Marquee
          {...sectionContent.marquee}
          theme={theme}
          css={{
            paddingX: 0,
            paddingTop: 0,
            paddingBottom: 80,
            '@md': { paddingBottom: 100 }
          }}
        />
        : null
    }
    css={{ '@md': { paddingBottom: 100 } }}
  >
    <ImagesMosaic items={sectionContent.scenarios} theme={theme}/>
  </SectionContainer>
)

export default Scenarios
