import React from 'react'

import SectionContainer from '@/Section/Container'
import TabsFields from '@/TabsFields'

const Tabs = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) => {
  return (
    <SectionContainer
      theme={theme}
      sectionHeader={sectionHeader}
    >
      <TabsFields
        key={Number(new Date())}
        {...sectionContent.tabsFields} />
    </SectionContainer>
  )
}

export default Tabs
