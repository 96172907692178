import React from 'react'
import { motion } from 'framer-motion'

import Row from './Row'

import { SlideUp } from 'animations'

import { StyledServicesRows, } from './styles'


const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      duration: 0.6,
      delayChildren: 0.2,
      staggerChildren: 0.2
    }
  },
}

const ServicesRows = ({ data }) => (
  <StyledServicesRows
    as={motion.div}
    variants={container}
    initial="hidden"
    whileInView="show"
    viewport={{ once: true }}
  >
    {
      data.map(({
        id,
        attributes
      }) => (
        <motion.div
          key={id}
          variants={SlideUp()}
        >
          <Row {...attributes} />
        </motion.div>
      ))
    }
  </StyledServicesRows>
)

export default ServicesRows
