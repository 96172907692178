import React from 'react'

import Icons from './Icons'
import { StyledIcon } from './styles'


export default function Icon({
  name,
  className,
  ...props
}) {
  const IconComponent = Icons[name]

  return (
    <StyledIcon {...props} className={`icon ${name} ${className ? className : ''}`}>
      {IconComponent ? <IconComponent /> : null}
    </StyledIcon>
  )
}
