import React from 'react'

import { StyledActiveJobButton } from './styles'

const ActiveJobButton = ({
  id,
  cluster,
  title,
  onClick,
  selected = false
}) => (
  <StyledActiveJobButton
    selected={selected}
    theme="secondary"
    size="big"
    onClick={() => onClick(cluster, id)}>
    {title}
  </StyledActiveJobButton>
)

export default ActiveJobButton
