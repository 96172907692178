import React, { useContext } from 'react'
import Script from 'next/script'

import { GeneralContext } from 'contexts/General'

const FooterScripts = () => {
  const { options } = useContext(GeneralContext)
  return (
    <>
      {
        options.chat ?
          <Script
            id="zsiqchat"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{ __html: `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${options.chat}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.eu/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);`, }}
          />
          : null
      }

    </>
  )
}

export default FooterScripts
