import React from 'react'

const marketplaceOnesolution = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.83205 10.7156C5.97869 9.17586 7.27186 8 8.81853 8H23.1814C24.7281 8 26.0212 9.17587 26.1679 10.7156L27.7869 27.7156C27.9547 29.4768 26.5697 31 24.8004 31H7.19949C5.43026 31 4.04526 29.4768 4.213 27.7156L5.83205 10.7156ZM8.81853 10C8.30297 10 7.87192 10.392 7.82304 10.9052L6.20399 27.9052C6.14808 28.4923 6.60974 29 7.19949 29H24.8004C25.3902 29 25.8518 28.4923 25.7959 27.9052L24.1769 10.9052C24.128 10.392 23.6969 10 23.1814 10H8.81853Z" />
    <path fillRule="evenodd" clipRule="evenodd" d="M11 6C11 3.23858 13.2386 1 16 1C18.7614 1 21 3.23858 21 6V12C21 12.5523 20.5523 13 20 13C19.4477 13 19 12.5523 19 12V6C19 4.34315 17.6569 3 16 3C14.3431 3 13 4.34315 13 6V12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12V6Z" />
  </svg>
)

export default marketplaceOnesolution
