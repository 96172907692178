import {
  styled, rem
} from 'config/stitches.config'

import Grid from '@/UI/Grid'
import Image from '@/Image'

Image.toString = () => '.GridImage'

export const StyledImageMosaic = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$3',

  '@md': {
    width: '80%',
    gap: rem(100)
  },

  [`${Grid}`]: {
    gap: '$3',
    '@md': { gap: rem(100) },
  }
})


export const StyledImageMosaicElement = styled('div', {
  paddingTop: 0,

  '&:last-child': {
    paddingLeft: rem(100),
    '@md': {
      paddingTop: rem(100),  paddingLeft: 0,
    }
  },

  '&:first-child': {
    paddingRight: rem(100),
    paddingTop: 0,
    '@md': {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  '@md': {
    paddingRight: 0,
    paddingLeft: 0,
  },

  variants: { full: { true: { '&:first-child, &:last-child': { padding: 0 } } } }

})


export const StyledImageMosaicElementContainer = styled('div', {
  position: 'relative',

  [Image]: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,

    'img': {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'center center',
    }
  },

  variants: {
    full: {
      true: { paddingTop: '56.25%', },
      false: { paddingTop: '133.33%', }
    }
  }

})
