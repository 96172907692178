import React from 'react'

import Tag from './index'
import { StyledTagList } from './styles'

const TagList = ({
  theme,
  tags = []
}) => (
  tags &&
  !!tags.length &&
  <StyledTagList className='TagList'>
    {tags.map(tag=> <Tag key={tag} label={tag} theme={theme} />)}
  </StyledTagList>
)

export default TagList
