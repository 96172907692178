import React, { useContext } from 'react'
import { MouseContext } from 'contexts/Mouse'

const withCustomCursor = (Component) => {
  const Comp = (props) => {
    const { cursor } = props
    const additionalProps = {}

    const {
      cursorChangeType,
      cursorChangeText,
      cursorChangeBlendMode,
    } = useContext(MouseContext)


    if (cursor) {
      additionalProps.onMouseEnter =  () => {
        cursorChangeType(cursor.type)
        cursorChangeText(cursor.text)
        cursorChangeBlendMode(cursor.blendMode)
      }
      additionalProps.onMouseLeave = () => {
        cursorChangeType()
        cursorChangeText()
        cursorChangeBlendMode()
      }
    }

    return <Component
      {...props}
      {...additionalProps}
    />
  }

  return Comp
}

export default withCustomCursor
