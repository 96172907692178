import React from 'react'

import Container from '@/UI/Container'
import RichText from '@/UI/RichText'
import Heading from '@/UI/Heading'
import FormComponent from '@/Form'

import {
  StyledContactRow,
  StyledContactTitle,
  StyledContactContent
} from './styles'

const SectionContact = ({ sectionContent }) => {
  return (
    sectionContent.sectionContact &&
    !!sectionContent.sectionContact.length &&
    sectionContent.sectionContact.map(({
      id,
      title,
      description,
      form
    }, idx) => (
      <Container
        key={id}
        css={{ marginTop: idx === 0 ? 120 : 0 }}
      >
        <StyledContactRow>
          {
            title &&
              <StyledContactTitle>
                <Heading as="h3" size="s2">
                  {title}
                </Heading>
              </StyledContactTitle>
          }

          <StyledContactContent>
            {
              description &&
                <RichText
                  size="p2-mid-mobile"
                  content={description} />
            }

            {
              form &&
                <FormComponent {...form} />
            }

          </StyledContactContent>
        </StyledContactRow>
      </Container>
    ))
  )
}

export default SectionContact
