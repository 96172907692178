import {
  styled,
  col
} from 'config/stitches.config'

const Column = styled('div', {
  variants: {
    col: {
      1: { width: col(1) },
      2: { width: col(2) },
      3: { width: col(3) },
      4: { width: col(4) },
      5: { width: col(5) },
      6: { width: col(6) },
      7: { width: col(7) },
      8: { width: col(8) },
      9: { width: col(9) },
      10: { width: col(10) },
      11: { width: col(11) },
      12: { width: col(12) },
    }
  },
  defaultVariants: { col: 12 }
})

export default Column
