import React from 'react'

import Text from '@/UI/Text'

import {
  StyledMarquee,
  StyledMarqueeInner,
} from './styles'

const Marquee = ({
  content = null,
  theme,
  ...props
}) => {
  if (!content) return <></>

  const MarqueeText = () => (
    <Text
      as="span"
      css={{ whiteSpace: 'nowrap', }}
      size={{
        '@initial': 'sliding-txt-mobile',
        '@md': 'sliding-txt'
      }}
    >{content}</Text>
  )

  return (
    <StyledMarquee {...props}>
      <StyledMarqueeInner className='MarqueeInner' aria-hidden="true" theme={theme}>
        {
          new Array(5).fill(MarqueeText).map((MarqueeEl, idx) => <MarqueeEl key={idx} />)
        }
      </StyledMarqueeInner>
    </StyledMarquee>
  )
}

export default Marquee
