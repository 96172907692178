import React from 'react'

import ActiveJobButton from '@/Buttons/ActiveJob'
import { StyledActiveFilters } from './styles'

const ActiveJobs = ({
  label = '',
  items,
  onClick
}) => (
  Object.entries(items).length > 0 &&
  <StyledActiveFilters>
    <span>{label}</span>
    {
      Object.entries(items).map(([cluster, item]) => (
        item
          ? <ActiveJobButton key={item.title + cluster} cluster={cluster} onClick={onClick} {...item}/>
          : null
      ))
    }
  </StyledActiveFilters>
)


export default ActiveJobs
