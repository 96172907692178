import React from 'react'

const customerCare = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M15.5 4C10.8056 4 7 7.80558 7 12.5V14.126C8.72523 14.5701 10 16.1362 10 18V21C10 23.2091 8.20914 25 6 25C3.79086 25 2 23.2091 2 21V18C2 16.1362 3.27477 14.5701 5 14.126V12.5C5 6.70101 9.70101 2 15.5 2C21.299 2 26 6.70101 26 12.5V14.126C27.7252 14.5701 29 16.1362 29 18V21C29 22.9002 27.6751 24.4909 25.8986 24.8987C25.4908 26.6751 23.9002 28 22 28H18.8293C18.4175 29.1652 17.3062 30 16 30C14.3431 30 13 28.6569 13 27C13 25.3431 14.3431 24 16 24C17.3062 24 18.4175 24.8348 18.8293 26H22C22.8108 26 23.5089 25.5175 23.8229 24.824C22.1882 24.3214 21 22.7995 21 21V18C21 16.1362 22.2748 14.5701 24 14.126V12.5C24 7.80558 20.1944 4 15.5 4ZM6 16C4.89543 16 4 16.8954 4 18V21C4 22.1046 4.89543 23 6 23C7.10457 23 8 22.1046 8 21V18C8 16.8954 7.10457 16 6 16ZM25 16C23.8954 16 23 16.8954 23 18V21C23 22.1046 23.8954 23 25 23C26.1046 23 27 22.1046 27 21V18C27 16.8954 26.1046 16 25 16ZM16 26C15.4477 26 15 26.4477 15 27C15 27.5523 15.4477 28 16 28C16.5523 28 17 27.5523 17 27C17 26.4477 16.5523 26 16 26Z"/>
  </svg>
)


export default customerCare
