import React from 'react'

import { useSwiper } from 'swiper/react'

import Icon from '@/UI/Icon'
import Button from '@/UI/Button'

import { StyledSliderNavigation } from './styles'


const styles = {
  button: {
    flexShrink: 0,
    justifyContent: 'center'
  },
  icon: { size: '80%' }
}

const SliderNavigation = ({
  // swiperInstance = null,
  id,
  onNext,
  onPrev,
  activeIndex,
  className,
}) => {
  // const swiper = swiperInstance || useSwiper()
  const swiper =  useSwiper()
  if (!swiper) throw Error('Swiper instance not found!')

  const totalSlides = swiper.slides.length
  const firstSlide = activeIndex === 1
  const lastSlide = activeIndex === totalSlides

  const goToNextSlide = () => {
    swiper.slideNext()
    if (onNext) onNext()
  }

  const goToPrevSlide = () => {
    swiper.slidePrev()
    if (onPrev) onPrev()
  }

  return (
    swiper &&
    <StyledSliderNavigation
      className={className}
      id={id}
    >
      <Button
        id='nav-prev'
        className='no-hover'
        size="verySmall"
        theme={firstSlide ? 'noBackground' : 'default'}
        disabled={firstSlide}
        css={styles.button}
        onClick={goToPrevSlide}
      >
        <Icon css={styles.icon} theme={firstSlide ? 'default' : 'inverted'} name="arrowLeft" />
      </Button>

      <Button
        id='nav-next'
        className='no-hover'
        size="verySmall"
        theme={lastSlide ? 'noBackground' : 'default'}
        disabled={lastSlide}
        css={styles.button}
        onClick={goToNextSlide}
      >
        <Icon css={styles.icon}  theme={lastSlide ? 'default' : 'inverted'} name="arrowRight" />
      </Button>
    </StyledSliderNavigation>
  )
}

export default SliderNavigation
