import {
  styled,
  rem
} from 'config/stitches.config'

import RichText from '@/UI/RichText'

export const StyledContentList = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  rowGap: '$4',
  marginTop: '$1',
})

export const StyledActiveFilters = styled('div', {
  borderTop: '1px solid $black',
  paddingTop: '$3',
  width: '100%',
  display: 'inline-flex',
  alignItems: 'center',
  columnGap: '$2',
  overflowX: 'scroll',
  overscrollBehavior: 'contain',
  paddingBottom: '$1',
  '&::-webkit-scrollbar': { height: 1, },

  '&::-webkit-scrollbar-track': { background: '$white', },
  '&::-webkit-scrollbar-thumb': { backgroundColor: '$secondary', },
  'button, span': { whiteSpace: 'nowrap' },
  '@md': { overflowX: 'auto', },
})


export const StyledFiltersCta = styled('div', { marginTop: rem(100) })

export const StyledFilterNoResult = styled(RichText, { paddingY: rem(32) })
