import React from 'react'
import { motion } from 'framer-motion'
import Paragraph from '@/Paragraph'

import { StyledParagraphsWithTitle } from './styles'
const container = {
  hidden: {},
  show: {
    transition: {
      duration: 0.6,
      staggerChildren: 0.3
    }
  },
}

const ParagraphsWithTitle = ({ items }) => (
  <StyledParagraphsWithTitle
    as={motion.div}
    variants={container}
    initial="hidden"
    whileInView="show"
    viewport={{ once: true }}
  >
    {
      items &&
      !!items.length &&
      items.map((item, idx) => <Paragraph key={idx} {...item} size="sub-desk" theme="lightGray" />)
    }
  </StyledParagraphsWithTitle>
)

export default ParagraphsWithTitle
