import React from 'react'
import { Swiper } from 'swiper/react'

import {
  Autoplay,
  EffectFade,
  Pagination,
  Navigation
} from 'swiper'


import { StyledSlider } from './styles'

const Slider = ({
  config,
  totalSlides,
  children,
  className = null,
  ...rest
}) => {
  const {
    autoplay,
    withPagination,
    ...otherConfig
  } = config
  let modules = [EffectFade]

  if (autoplay) modules = [...modules, Autoplay, Navigation]
  if (!!totalSlides && withPagination) modules = [...modules, Pagination]

  return (
    <StyledSlider className={className}>
      <Swiper
        modules={modules}
        autoplay={autoplay}
        {...otherConfig}
        {...rest}
      >
        {children}
      </Swiper>
    </StyledSlider>
  )
}

export default Slider
