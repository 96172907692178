import {
  styled, rem
} from 'config/stitches.config'


export const StyledList = styled('div', {
  display: 'block',
  width: '100%',
  overflowX: 'scroll',
  overscrollBehavior: 'contain',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': { display: 'none' },
  scrollBehavior: 'smooth',

})
export const StyledListContainer = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: '$3',
  marginTop: rem(64),
  '@md': { flexWrap: 'wrap', }
})


export const StyledImageWithLabel = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flexShrink: 0,
  backgroundColor: '$pictureBackground',
  columnGap: '$2',
  padding: '$2',
  height: 56,
  br: 70,

  '.TinyImage': {
    size: '100%',
    flexShrink: 0,
    filter: 'grayscale(100%)',
    objectFit: 'contain',
    objectPosition: 'center',
  },

  '&:hover': { '.TinyImage': { filter: 'grayscale(0%)' } }
})


export const StyledImageWrapper = styled('div', { size: 24 })

export const StyledLabel = styled('span', {
  flexShrink: 0,
  transformOrigin: 'center left'
})
