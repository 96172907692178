import React from 'react'
import SectionContainer from '@/Section/Container'

import SliderReviewsList from '@/Sliders/SliderReviewsList'


const ReviewsList = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) => {
  return (
    <SectionContainer
      theme={theme}
      sectionHeader={sectionHeader}
    >
      <SliderReviewsList items={sectionContent.reviews} />
    </SectionContainer>
  )
}

export default ReviewsList
