const breakpoints = {
  sm: 480,
  md: 768,
  middle: 900,
  lg: 1024,
  xl: 1792,
}

export const media = {
  sm: `(min-width: ${breakpoints.sm}px)`,
  md: `(min-width: ${breakpoints.md}px)`,
  middle: `(min-width: ${breakpoints.middle}px)`,
  lg: `(min-width: ${breakpoints.lg}px)`,
  xl: `(min-width: ${breakpoints.xl}px)`,
}


export default breakpoints
