import React from 'react'
import { useRouter } from 'next/router'

import { StyledLink } from './styles'

const SimpleLink = ({
  children,
  className = null,
  href = '',
  target = '_self',
  disabled = false,
  lang = null,
  ...otherLinkProps
}) => {
  const { locale } = useRouter()

  return (
    href ?
      <StyledLink
        className={className}
        href={href}
        locale={lang || locale}
        target={target}
        disabled={disabled}
        {...otherLinkProps}
      >
        {children}
      </StyledLink>
      : null
  )
}

export default SimpleLink
