import React, { useState } from 'react'

import ContentDivider from '@/ContentDivider'

import Tabs from '@/UI/Tabs'
import Grid from '@/UI/Grid'
import RichText from '@/UI/RichText'


import { StyledTabsContentSingle, } from './styles'

const TabsFields = ({
  theme,
  header,
  content
}) => {
  const [selectedTab, setSelectedTab] = useState(header[0].id || null)

  const onTabButtonClick = (tabID) => {
    setSelectedTab(tabID)
  }
  return (
    <Tabs
      theme={theme}
      header={header}
      selectedTab={selectedTab}
      onTabClick={onTabButtonClick}
    >
      {
        content.map(({
          id,
          items
        }) =>
          id === selectedTab
          && (
            <Grid
              key={id}
              css={{
                columnGap: 80, rowGap: 64
              }}
              columns={{
                '@initial': 1,
                '@md': 2
              }} >

              {items.map((item, idx) => (
                <StyledTabsContentSingle key={item.id}>
                  <ContentDivider lineProps={{ animate: 'show' }} theme="inverted" label={idx < 9 ? `0${idx + 1}` : idx + 1} />
                  <RichText as='div' content={item.description} />
                </StyledTabsContentSingle>
              ))}
            </Grid>
          )
        )
      }
    </Tabs>

  )
}

export default TabsFields
