import React from 'react'
import Text from '@/UI/Text'

const RichText = ({
  content,
  ...props
}) => (
  <Text
    {...props}
    dangerouslySetInnerHTML={{ __html: content }}
  />
)

export default RichText
