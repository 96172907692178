import React from 'react'

import { rem } from 'config/stitches.config'

import SectionContainer from '@/Section/Container'

import MarqueeComponent from '@/Marquee'
import ImagesWithTag from '@/ImagesWithTag'

import SimpleLink from '@/UI/Link'

const Marquee = ({
  content,
  href,
  imagesWithTag,
  labelCta,
  alignment,
  theme = 'default',
  ...rest
}) => {
  const withImg = imagesWithTag && !!imagesWithTag.length

  const Wrapper = (props) => href ? <SimpleLink href={href} aria-label={content} css={{ textDecoration: 'none' }} {...props} /> : <>{props.children}</>

  const containerProps = {
    css: {
      position: 'relative',
      paddingX: 0,
      paddingY: rem(80),
      '@xl': { maxWidth: '100%', }
    },
    ...rest
  }
  if (href) {
    Object.assign(containerProps, {
      cursor: {
        type: `${theme}Link`,
        text: labelCta,
        blendMode: 'normal'
      }
    })
  }

  return (
    <SectionContainer
      {...containerProps}
    >
      <Wrapper>
        <MarqueeComponent theme={theme} absolute={withImg} content={content} />
        <ImagesWithTag className="MarqueeImages" items={imagesWithTag} alignment={alignment} theme={theme} />
      </Wrapper>
    </SectionContainer>
  )
}

export default Marquee
