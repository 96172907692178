import { styled } from 'config/stitches.config'

import Text from '@/UI/Text'

export const StyledTabsContentSingle = styled('div', {
  [`${Text}`]: {
    marginTop: '$3',
    p: { margin: 0 }
  }
})
