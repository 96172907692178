import React from 'react'

import SectionContainer from '@/Section/Container'
import Stats  from '@/Stats'

const Statistics = ({
  sectionGeneral: { theme },
  sectionHeader,
  sectionContent
}) => {
  return (
    <SectionContainer
      theme={theme}
      sectionHeader={sectionHeader}
    >
      <Stats items={sectionContent.statsItems} />
    </SectionContainer>
  )
}

export default Statistics
